<template>
  <div :key="updateView">
    <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false"> Ci sono modifiche non salvate </el-alert>
    <el-card class="box-card">
      <el-descriptions v-if="currentApartment" class="margin-top table-fixed" :title="'Info Appartamento - '+getFullName(currentApartment.user)" :column="3" direction="vertical" border>
        <template #extra>
          <el-button  v-if="hasEditedFields" type="primary" @click="saveEdits"><i
              class="el-icon-upload"></i> Salva Modifiche
          </el-button>
        </template>
        <el-descriptions-item v-for="apartment in apartmentDescriptionFields" :key="apartment.field"
                              :span="apartment.span">
          <template #label>
            <div class="justify-space-between" style="display: flex; align-items: flex-end">
              <span>
                <i :class="apartment.icon"></i> {{ apartment.label }}
              </span>
              <span style="float: right" v-if="apartment.editable && isGranted('ROLE_MANAGER')" @click="apartment.onEdit(apartment)"
                    class="pointer edit-button"><i class="el-icon-edit"></i>Modifica</span>
            </div>
          </template>
          {{ apartment.value }}
          <el-tooltip v-if="apartment.field==='relativeConsumption'" class="item" effect="dark"
                      :content="'Differenza consumi: ' + (difference).toFixed(2) + '%'">
            <i :class="getIcon(difference/100)"></i>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      <simple-input-dialog ref="simpleInputDialog" @submit="updateCurrentField"></simple-input-dialog>
      <simple-input-dialog ref="simpleKDialog" @submit="updateCurrentField"></simple-input-dialog>
      <change-user-dialog v-if="isGranted('ROLE_MANAGER')" ref="simpleSelectDialog" @submit="updateCurrentSelect"></change-user-dialog>
    </el-card>
    <el-card v-if="currentApartment" class="box-card">
      <meter-list></meter-list>
    </el-card>
    <el-card class="box-card">
      <el-button type="primary" @click="$router.push('/dashboard/letture/'+currentApartment.id)"> Visualizza letture
      </el-button>
    </el-card>
  </div>
</template>

<script>
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";
import IconsMixin from "../../mixins/IconsMixin";
import SimpleInputDialog from "../../components/SimpleInputDialog";
import {mapActions, mapGetters} from "vuex";
import MeterList from "./MeterList";
import ChangeUserDialog from "../../components/ChangeUserDialog";


export default {
  name: "Apartment",
  mixins: [UserMixin, AddressMixin, IconsMixin],
  components: {ChangeUserDialog, SimpleInputDialog, MeterList},
  data() {
    return {
      apartmentDescriptionFields: [],
      hasEditedFields: false,
      editedFields: {},
      currentField: null,
      newMeterFields: [],
      difference: 0,
      relativeConsumption: 0,
      targetConsumption: 0,
      updateView: false,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "basicUserList", "currentApartment", "appUser", "isGranted", "condominiumAdministratorList"]),
  },
  methods: {
    ...mapActions(["fetchUser", "fetchApartment", "fetchAllCondominiumAdministrators", "updateApartment", "fetchAllBasicUsers"]),
    editField(field) {
      this.currentField = field;
      this.$refs.simpleInputDialog.title = field.label;
      this.$refs.simpleInputDialog.formData = field.value;
      this.$refs.simpleInputDialog.show();
    },
    editK(field) {
      this.currentField = field;
      this.$refs.simpleKDialog.title = field.label;
      this.$refs.simpleKDialog.formData = field.value;
      this.$refs.simpleKDialog.type = "number"
      this.$refs.simpleKDialog.show();
    },
    updateCurrentField(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      this.currentField.value = data;
    },
    async editUser(field) {
      this.currentField = field;
      this.$refs.simpleSelectDialog.formData = field.value;
      this.$refs.simpleSelectDialog.show();
    },
    async updateCurrentSelect(data) {
      this.hasEditedFields = true;
      this.editedFields[this.currentField.field] = data;
      await this.fetchAllBasicUsers()
      this.currentField.value = this.getFullName(this.basicUserList.find(u => u.id === data));
    },
    async saveEdits() {
      await this.updateApartment({...this.editedFields});
      this.hasEditedFields = false;
    },
    getConfirm() {
      return this.hasEditedFields
    },
    createFields(){
      this.relativeConsumption = this.currentApartment.consumption / this.currentApartment.condominium.totalConsumption * 100;
      this.targetComsumption = this.currentApartment.kApartment / this.currentApartment.condominium.totalK * 100;
      this.difference = ((this.relativeConsumption - this.targetComsumption) / this.targetComsumption)*100;

      this.apartmentDescriptionFields = [
        {
          field: "condominium",
          value: this.stringifyAddress(this.currentApartment.condominium.address),
          label: "Indirizzo condominio - "+this.currentApartment.condominium.description,
          icon: "el-icon-location-outline",
          span: 2,
          editable: false,
        }, {
          field: "user",
          value: this.getFullName(this.currentApartment.user),
          label: "Utente",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editUser,
        }, {
          field: "floor",
          value: this.currentApartment.floor,
          label: "Piano/Scala",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "number",
          value: this.currentApartment.number,
          label: "Numero",
          icon: "el-icon-user",
          span: 1,
          editable: true,
          onEdit: this.editField,
        }, {
          field: "kApartment",
          value: this.currentApartment.kApartment,
          label: "K Appartamento",
          icon: "el-icon-message",
          span: 1,
          editable: true,
          onEdit: this.editK,
        },
        // {
        //   field: "owner",
        //   value: this.getFullName(this.currentApartment.condominium.owner),
        //   label: "Owner",
        //   icon: "el-icon-user",
        //   span: 1,
        //   editable: false
        // },
        {
          field: "condominiumAdministrator",
          value: this.getFullName(this.currentApartment.condominium.condominiumAdministrator),
          label: "Amministratore Condominiale",
          icon: "el-icon-user",
          span: 1,
          editable: false
        },
        // {
        //   field: "supplier",
        //   value: this.getFullName(this.currentApartment.condominium.supplier),
        //   label: "Fornitore Servizi",
        //   icon: "el-icon-user",
        //   span: 1,
        //   editable: false
        // },
        // {
        //   field: "serviceTechician",
        //   value: this.getFullName(this.currentApartment.condominium.serviceTechnician),
        //   label: "Manutentore",
        //   icon: "el-icon-user",
        //   span: 1,
        //   editable: false
        // },
        {
          field: "description",
          value: this.currentApartment.description,
          label: "Descrizione",
          icon: "el-icon-message",
          span: 2,
          editable: true,
          onEdit: this.editField,
        },
        {
          field: "consumption",
          value: this.currentApartment.consumption,
          label: "Consumi appartamento",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "relativeConsumption",
          value: this.relativeConsumption.toFixed(2),
          label: "Consumi %",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        },
        {
          field: "targetConsumption",
          value: this.targetComsumption.toFixed(2),
          label: "Consumo ideale %",
          icon: "el-icon-message",
          span: 1,
          editable: false,
        }
      ];

    }
  },
  async created() {

    const apartmentId = this.$route.params.id;

    await this.fetchApartment(apartmentId);
    this.createFields()

    window.onbeforeunload = () => {
      return this.getConfirm();
    };

  },
  watch: {
    async $route(to, from) {
      if (to !== from && to.name === from.name) {
        const apartmentId = this.$route.params.id;
        if (apartmentId){
          await this.fetchApartment(apartmentId);
          this.createFields()
        }
      }
      //this.updateView = !this.updateView
    }
  }



  // }
}
</script>

<style>

.justify-space-between {
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}
</style>
