<template>
  <el-dialog v-if="showDialog" title="Cambia Utente" v-model="showDialog" :close-on-click-modal="false">
    <el-form>
      <el-form-item label="Seleziona un altro utente">
        <el-select v-model="formData" filterable>
          <el-option
              v-for="item in items"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>

      </el-form-item>
    </el-form>
    <el-divider> oppure  </el-divider>
    <el-button type="primary" icon="el-icon-plus" @click="$refs.createUserDialog.show()">{{$t("buttons.user.create")}}</el-button>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>

    <form-dialog :title="'Crea Utente'" :fields="newUserFields" @submit="submittedCreateUser"
                 ref="createUserDialog"></form-dialog>
  </el-dialog>




</template>

<script>
import FormDialog from "./FormDialog";
import {mapActions, mapGetters} from "vuex";
import {getNewSimpleUserFields} from "@/static/formFields";
import UserMixin from "../mixins/UserMixin";

export default {
  name: "ChangeUserDialog",
  components: {FormDialog},
  mixins: [UserMixin],
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      formData: "",
      title: "",
      newUserFields: [],
      items: [],
    }
  },
  computed: {
    ...mapGetters(["homelessUserList", "condominiumAdministratorList"]),
  },
  methods: {
    ...mapActions(["registerUser", "fetchAllHomelessUsers"]),
    show(){
      this.showDialog = true;
    },
    submit() {
      this.$emit("submit", this.formData);
      this.showDialog = false;
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    async submittedCreateUser(data) {
      if(data.admin){
        data.type = "admin";
      }
      let res = await this.registerUser({...data});
      await this.fetchList();
      this.formData = res.id;
      this.submit()
    },
    async fetchList(){
      await this.fetchAllHomelessUsers();
      let basicUserList = Object.values(this.homelessUserList).map(el => {
        return {
          value: el.id,
          label: this.getFullName(el)
        }
      });
      // console.log(basicUserList)
      this.items = basicUserList.sort((first, second) => {
        return first.label.localeCompare(second.label, "it",  { sensitivity: 'base' });
      })
    }
  },
  async created() {
    await this.fetchList();
    // console.log(this.items)
    this.newUserFields = getNewSimpleUserFields();
  }

}
</script>

<style scoped>

</style>

