<template>
  <div>
    <h1>Lista contatori</h1>
    <div v-if="isGranted('ROLE_MANAGER')">
      <div class="button-container">
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="openCreateMeterDialog">Aggiungi Contatore</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="openCreateReadingDialog">Aggiungi Lettura</el-button>
        </div>
        <el-switch
            v-model="showActiveOnly"
            active-text="Visualizza solo attivi"
            inactive-text=""
            @change="loadList()"
        >
        </el-switch>
        <el-button  v-if="hasEditedFields" type="primary" @click="saveEdits"><i
            class="el-icon-upload"></i> {{ $t("buttons.saveUpdate") }}
        </el-button>
      </div>
      <el-alert v-if="hasEditedFields" type="warning" show-icon style="margin-bottom: 15px" :closable="false"> Ci sono modifiche non salvate </el-alert>
    </div>
    <el-row>
      <el-col v-if="currentApartment.meters">
        <el-table
            :data="currentApartment.meters.filter( meter => {
              return meter.active || !showActiveOnly
            })"
        >
          <el-table-column prop="id" label="ID" width="50"></el-table-column>
          <el-table-column prop="description" label="Descrizione"></el-table-column>
          <el-table-column prop="serialNumber" label="Seriale">
            <template #default="scope">
              {{ scope.row.serialNumber }}
            </template>
          </el-table-column>
          <el-table-column prop="brand" label="Marca">
            <template #default="scope">
              {{ scope.row.brand }}
            </template>
          </el-table-column>
          <el-table-column prop="meterType" label="Tipo">
            <template #default="scope">
              {{ scope.row.meterType.type }}
            </template>
          </el-table-column>
          <el-table-column prop="kMeter" label="K">
            <template #default="scope">
              {{ scope.row.kMeter }}
            </template>
          </el-table-column>
          <el-table-column prop="meterActivate" label="Stato contatore">
            <template #default="scope">
              <i v-if="scope.row.active === true" class="el-icon-success color-success"></i>
              <i v-else class="el-icon-error color-danger"></i>
            </template>
          </el-table-column>
          <el-table-column v-if="isGranted('ROLE_MANAGER')" prop="meterActivate" label="On/Off">
            <template #default="scope">
              <span class="pointer">
                <i class="el-icon-open" @click="toggleActive(scope.row)"> </i>
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="isGranted('ROLE_MANAGER')" prop="edit" label="Modifica">
            <template #default="scope">
              <span class="pointer">
                <i class="el-icon-edit" @click="editMeter(scope.row)"> </i>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <form-dialog :title="'Crea Contatore'" :fields="newMeterFields" @submit="submittedCreateMeter"
                 ref="createMeterDialog" v-if="isGranted('ROLE_MANAGER')"></form-dialog>
    <form-dialog :title="'Modifica Contatore'" :fields="newMeterFields" @submit="submittedEditMeter"
                 ref="editMeterDialog" v-if="isGranted('ROLE_MANAGER')"></form-dialog>
    <create-reading-dialog ref="createReadingDialog" v-if="isGranted('ROLE_MANAGER')"></create-reading-dialog>
  </div>
</template>

<script>

import FormDialog from "../../components/FormDialog";
import {mapActions, mapGetters} from "vuex";
import {getNewMeterFields} from "../../static/formFields";
import CreateReadingDialog from "../../components/CreateReadingDialog";
//import {getNewApartmentFields} from "../../static/formFields";

export default {

  name: "MeterList",
  components: {CreateReadingDialog, FormDialog},
  mixins: [],
  data() {
    return {
      newMeterFields: [],
      meterStatus: 0,
      buttonText: "Disattiva",
      selectedMeter: null,
      updatedMeters: {},
      meters: null,
      hasEditedFields: false,
      meterTypes : [
        {
          id: 1,
          label: "Calore"
        },
        {
          id: 2,
          label: "Acqua Calda"
        },
        {
          id: 3,
          label: "Acqua Fredda"
        },
        {
          id: 4,
          label: "Ripartitore"
        },
      ],
      showActiveOnly: true
    }
  },
  computed: {
    ...mapGetters(["currentApartment", "isGranted", "currentMeter"])
  },
  methods: {
    ...mapActions(["createMeter", "updateMeter", "fetchMeter", "fetchApartment"]),
    async submittedCreateMeter(data) {
      await this.createMeter({...data});
    },
    // handle pop up contatori
    toggleActive(meter) {
      let message = "";
      if (meter.active) {
        message = 'Sei sicuro di voler disattivare questo contatore?'
      } else {
        message = 'Sei sicuro di voler attivare questo contatore?'
      }
      this.$confirm(message, 'Attenzione', {
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla',
        type: 'warning'
      }).then(async () => {
        await this.fetchMeter(meter.id);
        await this.updateMeter({"active": !meter.active});
        await this.fetchApartment(this.currentApartment.id);
        this.$message({
          type: 'success',
          message: 'Operazione completata',
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operazione annullata'
        });
      });
    },
    async openCreateMeterDialog() {
      this.newMeterFields = getNewMeterFields(this.meterTypes);
      this.$refs.createMeterDialog.show();
    },
    editMeter(meter) {
      this.selectedMeter = meter;

      this.newMeterFields = getNewMeterFields(this.meterTypes);
      Object.entries(meter).forEach((field)=>{
        this.$refs.editMeterDialog.formData[field[0]] = field[1]
      });

      this.$refs.editMeterDialog.show();
    },
    async submittedEditMeter(data){
      //console.log(Object.entries(this.selectedMeter))
      this.updatedMeters[data.id] = {}
      Object.entries(data).forEach( field => {
        if (field[0]==="meterType") {
          if (this.selectedMeter.meterType.id !== data.meterType.id){
            this.updatedMeters[data.id]["meterType"] = field[1];
            console.log(data.meterType)
            this.selectedMeter.meterType = {
              id: data.meterType,
              type: this.meterTypes[data.meterType-1].label
            }
          }
        } else if (this.selectedMeter[field[0]] !== field[1]){
          this.updatedMeters[data.id][field[0]] = field[1];
          this.selectedMeter[field[0]] = field[1];
        }
      });

      if (Object.keys(this.updatedMeters[data.id]).length > 0){
        this.hasEditedFields = true
      }

    },
    getConfirm() {
      return this.hasEditedFields
    },
    async saveEdits(){
      console.log(this.updatedMeters)
      Object.entries(this.updatedMeters).forEach(async (meter) => {
        await this.fetchMeter(meter[0]);
        await this.updateMeter(meter[1]);
      })

      this.$message({
        type: 'success',
        message: 'Operazione completata',
      });
      this.hasEditedFields = false;
    },
    openCreateReadingDialog(){
      this.$refs.createReadingDialog.show();
    }
  },
  created() {
    window.onbeforeunload = () => {
      return this.getConfirm();
    };
  }
}
</script>

<style scoped>
  .button-container{
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

  }
</style>
